.vertical {
  position: relative;
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  align-content: center;

  input[type=range] {
    -webkit-appearance: none;
    transform: rotate(270deg) translateY(0%);
    width: 150px;
    outline: none;
    background: none;
    align-items: center;
    display: block;
  }

  input[type=range]::-webkit-slider-runnable-track,
  input[type="range"]::-moz-range-track {
    -webkit-appearance: none;
    background-color: #444;
    color: #444;
    height: 20px;
    width: 200px;
  }
}

.horizontal {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  // scale
  background: repeating-linear-gradient(90deg, white, white 2px, transparent 3px, transparent 21px);

  input[type=range] {
    -webkit-appearance: none;
    width: 100%;
    outline: none;
    position: relative;
    background: none;
    height: 40px;
  }

  input[type=range]::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    background-color: #444;
    color: #444;
    height: 20px;
    width: 200px;
  }
}

.vertical, .horizontal {
  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: linear-gradient(to right, grey 0%, grey calc(50% - 1px), white calc(50% - 1px), white calc(50% + 1px), grey calc(50% + 1px), grey 100%);
    opacity: 1;
    width: 15px;
    height: 40px;
    transform: translateY(-10px);
  }
}

.iconContainer {
  width: 40px;
  height: 40px;
  margin: 0 auto;

  svg g {
    fill: #e0e0e0;
  }
}

.divider {
  width: 60%;
  margin: 18px auto 11px auto;
  border: 1px solid #c6c5c5;
}

.columns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.synth-wrapper {
    width: 1200px;
    height: auto;
    background-color: #585d65;
    margin: 20px auto;
    border-radius: 10px;
    overflow: hidden;
    border-top: 2px;
    border-bottom: 2px;
    border-right: 1px;
    border-left: 1px;
    border-style: solid;
    border-color: #707070;
    padding: 10px;
}

.container {
    color: white;
    display: flex;
}

canvas.visualizer {
    background-color: lightgrey;
    margin: 15px 15px 0 15px;
}

.custom-input {
  display: inline-block;
  label {
    display: inline-block;
    margin: 10px 20px 20px 0;
    border: none;
    background-color: #ffffff1a;
    padding: 10px 20px;
    color: #fff;
    cursor: pointer;
  }
    input[type='file']::file-selector-button {
        margin: 10px 15px 20px 0;
        border: none;
        background-color: #ffffff1a;
        padding: 10px 20px;
        border-radius: 10px;
        color: #fff;
        cursor: pointer;
    }
    input[type=file]::file-selector-button:hover {
      background: #0d45a5;
    }
    .midi-name {
      white-space: nowrap;
      color: #fff;
      display: inline-block;
      text-overflow: ellipsis;
      max-width: 250px;
      overflow: hidden;
    }
}
